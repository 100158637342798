import React from "react";
import Link from "gatsby-link";

import { Jumbotron, Grid, Row, Col, Image, Button } from "react-bootstrap";
import AltHeader from "../../components/alt-header.js";
import Header from "../../components/header.js";
import UpNext from "../../components/up-next.js";
import Layout from "../../components/Layout";
import JobHeader from "../../components/job-header.js";
const SuperdrugPage = () => (
  <Layout>
    <JobHeader
      image="/img/superdrug-header2.jpg"
      title="Superdrug"
      subtitle="A Health & Beauty specialist based in the UK"
      text="Digital Design"
    />

    <Row className="show-grid side-padding intro">
      <Col xs={12} sm={10} smOffset={2} md={10} mdOffset={2}>
        <h2>My Role</h2>
        <p>
          <em>
            My role at A.S Watson involves supporting the Business Units with
            their online activities. Thise includes supporting Superdrug,
            helping them to create compelling landing pages and social media
            posts. One such landing page was for their 'Optimum' range which can
            be seen below.
          </em>
        </p>
      </Col>
    </Row>

    <Row className="show-grid pt40">
      <Col xs={12} md={12}>
        <Image src="/img/superdrug/superdrug-desktop.jpg" />
      </Col>
    </Row>

    <UpNext
      image1="/img/marionnaud-card2.jpg"
      title1="Marionnaud"
      text1="A European Health & Beauty brand"
      url1="/marionnaud/"
      image2="/img/superdrug-mobile-card.jpg"
      title2="Superdrug Mobile"
      text2="Creating a mobile brand from scratch"
      url2="/perfume-shop/"
      image3="/img/spread-co-card.jpg"
      title3="Spread Co"
      text3="A financial spread betting company"
      url3="/spread-co/"
    />
  </Layout>
);

export default SuperdrugPage;
